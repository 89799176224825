<template>
  <Layout>
    <support-tabs :type="type"/>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main";
import SupportTabs from "@/components/support/SupportTabs.vue";
export default {
  data() {
    return {
      type: this.$route.params.type,
    };
  },
  watch: {
    "$route.params.type"(value) {
      this.type = value;
    },
  },
  components: {
    Layout,
    SupportTabs,
  },
};
</script>
