<template>
  <div>
    <div class="support-title">
      {{ supportType.find((item) => item.value === type).label }}
    </div>
    <b-tabs content-class="mt-3" v-on:activate-tab="tabChanged">
      <b-tab title="Pending" active>
        <support-table
          v-if="currentTab === 0"
          status="false"
          :type="supportType.find((item) => item.value === type).label"
        />
      </b-tab>
      <b-tab title="Completed">
        <support-table
          v-if="currentTab === 1"
          status="true"
          :type="supportType.find((item) => item.value === type).label"
        />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import supportType from "@/config/data/support";
import SupportTable from "./SupportTable.vue";
export default {
  props: ["type"],
  data() {
    return {
      supportType: supportType,
      currentTab: 0,
    };
  },
  components: {
    SupportTable,
  },
  methods: {
    tabChanged(val) {
      this.currentTab = val;
    },
  },
};
</script>

<style scoped>
.support-title {
  text-align: center;
  width: 180px;
  margin: auto;
  font-weight: 900;
  font-size: 14px;
  padding: 4px 12px;
  border-radius: 12px;
  background-color: rgb(255, 255, 255);
}
</style>
