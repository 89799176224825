export const support = {
  getAllSupportRequests: {
    url: "supports/getAllSupportRequests",
    method: "GET",
    params: null,
  },
  changeStatus:{
    url: "supports/changeStatus",
    method: "POST",
    data: null,
  },
  changePriority:{
    url: "supports/changePriority",
    method: "POST",
    data: null,
  }
};
