<template>
  <div>
    <div v-if="loading" style="text-align: center; margin-top: 28px">
      <pulse-loader :loading="loading"></pulse-loader>
    </div>
    <div v-else>
      <div v-if="data.length > 0">
        <b-table striped hover :items="data" :fields="fields">
          <template v-slot:cell(updatedAt)="row">
            {{ moment(row.item.updatedAt).format("LLL") }}
          </template>
          <template v-slot:cell(priority)="row">
            {{ priority[parseInt(row.item.priority)] || "N/A" }}
          </template>
          <template v-slot:cell(action)="row">
            <b-button v-if="status == 'false'" @click="closeTicket(row.item)">
              Close
            </b-button>
            <b-button v-else @click="openTicket(row.item)"> Open </b-button>
            <b-button class="mt-1" @click="changePriority(row.item)">
              Priority
            </b-button>
          </template>
        </b-table>
        <div class="d-flex justify-content-end">
          <b-pagination
            :value="page"
            @change="pageChange"
            :total-rows="rows"
            :per-page="perPage"
            pills
          ></b-pagination>
        </div>
      </div>
      <div v-else class="text-center py-4">No Data</div>
    </div>
    <priority-modal
      v-on:reloadData="loadData"
      :selectedSupport="selectedSupport"
      @modalClosed="selectedSupport = null"
    />
  </div>
</template>

<script>
import { support } from "@/config/api/support";
import priority from "@/config/data/priority";
import PriorityModal from "./PriorityModal";

export default {
  props: ["status", "type"],
  created() {
    this.loadData();
  },
  data() {
    return {
      page: 1,
      perPage: 10,
      data: [],
      rows: 0,
      priority: priority,
      loading: false,
      selectedSupport: null,
      fields: ["title", "description", "priority", "updatedAt", "action"],
    };
  },
  components: {
    PriorityModal,
  },

  watch: {
    type() {
      this.loadData();
    },
  },
  methods: {
    loadData() {
      const api = support.getAllSupportRequests;
      api.params = {
        page: this.page,
        perPage: this.perPage,
        status: this.status,
        type: this.type,
      };
      this.loading = true;
      this.generateAPI(api)
        .then((res) => {
          this.data = res.data.supportRequests.docs;
          this.rows = res.data.supportRequests.totalDocs;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    pageChange(val) {
      this.page = val;
      this.loadData();
    },
    closeTicket(item) {
      const api = support.changeStatus;
      api.data = {
        id: item._id,
        status: parseInt(1),
      };
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to close the ticket", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.generateAPI(api).then(() => {
              this.page = 1;
              this.loadData();
            });
          }
        });
    },
    openTicket(item) {
      console.log("Open->", item);
      const api = support.changeStatus;
      api.data = {
        id: item._id,
        status: "0",
      };
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to open the ticket", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.generateAPI(api).then(() => {
              this.page = 1;
              this.loadData();
            });
          }
        });
    },
    changePriority(item) {
      this.selectedSupport = item;
    },
  },
};
</script>
