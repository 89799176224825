<template>
  <b-modal id="priority-modal" @hide="closeModal" hide-footer>
    <div v-if="selectedSupport">
      <div class="text-center mb-4" style="margin-top: -28px">
        Change priority (
        <span style="font-weight: 900">{{
          priority[parseInt(selectedSupport.priority)]
        }}</span>
        )
      </div>
      <FormulateForm :values="formValues" @submit="submitHandler">
        <FormulateInput
          name="priority"
          :options="{ 0: 'Low', 1: 'Medium', 2: 'High' }"
          type="radio"
        />
        <div class="text-center">
          <FormulateInput
            type="submit"
            :disabled="isLoading"
            :label="isLoading ? 'Changing...' : 'Change Priority'"
          />
        </div>
      </FormulateForm>
    </div>
  </b-modal>
</template>

<script>
import priority from "@/config/data/priority";
import { support } from "@/config/api/support";
export default {
  props: ["selectedSupport"],
  data() {
    return {
      priority: priority,
      isLoading: false,
      formValues: { priority: "0" },
    };
  },
  watch: {
    selectedSupport(val) {
      if (val && val._id) {
        this.$bvModal.show("priority-modal");
        this.formValues.priority = "" + val.priority;
      }
    },
  },
  methods: {
    closeModal() {
      this.$bvModal.hide("priority-modal");
      this.$emit("modalClosed");
    },
    submitHandler(data) {
      const api = support.changePriority;
      api.data = {
        id: this.selectedSupport._id,
        priority: data.priority,
      };
      this.isLoading = true;
      this.generateAPI(api)
        .then(() => {
          this.$bvToast.toast("Priority Changed", {
            title: "Support Priority",
            variant: "success",
            solid: true,
          });
          this.$emit("reloadData");
          this.closeModal();
        })
        .catch((err) => {
          this.$bvToast.toast(
            `Priority Changed, ${err.response.data.message}`,
            {
              title: "Support Priority",
              variant: "danger",
              solid: true,
            }
          );
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style>
#priority-modal .formulate-input-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
#priority-modal .formulate-input-label--after {
  font-size: 16px !important;
}

#priority-modal .formulate-input-element--radio {
  margin-top: 6px;
}
</style>
